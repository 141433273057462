
<!-- this component should not use slots -->
<!-- this component should not inherit attrs -->

<!-- need to test mobile when making any changes -->

<script lang="ts">
    export interface IProps {
        /* size */
        size?: "sm" | "md" | "lg";
        /* text */
        text?: string;
        /* href */
        href?: string;
        /* icon */
        icon?: string;
        /* side the icon will be on (default is left) */
        iconSide?: "top" | "left" | "bottom" | "right";
        /* force a lookup icon and set iconSide to right */
        lookup?: boolean;
        /* shows spinner and disables button */
        loading?: boolean;
        /* disables button */
        disabled?: boolean;
        /* type of button */
        variant?: "contained" | "outline" | "link";
        /* bootstrap colors */
        color?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
        /* flex grow */
        grow?: boolean;
        /* open in new tab (only for desktop, mobile or tablet will never open in new tab) */
        newTab?: boolean;
        /* to access the exact button element */
        target?: Function;
        /* internal */
        onClick?: Function;
    };

    export interface IEmits {
        (e: "click", event: MouseEvent): void;
    };
</script>

<script setup lang="ts">
    import { ref, computed, watch, onMounted } from "vue";
    import { isMobile, isMobileOrTablet } from "o365.GlobalState.ts";

    // defineOptions({ inheritAttrs: false });

    const props = withDefaults(defineProps<IProps>(), { size: "md", variant: "contained", color: "primary", iconSide: "left" });
    const emit = defineEmits<IEmits>();

    // props override
    const icon = computed(() => props.lookup ? "bi-caret-down-fill font-n4" : props.icon);
    const iconSide = computed(() => props.lookup ? "right" : props.iconSide);
    const disabled = computed(() => props.disabled || props.loading);

    // helpers
    const hasIcon = computed(() => icon.value || props.loading);
    const isClickable = computed<boolean>(() => !!props.href || !!props.onClick || true);

    const elRef = ref<HTMLElement>();

    // class
    const className = computed(() => {
        const classes: Array<string> = ["btn"];
        if (props.size === "sm") {
            classes.push(isMobileOrTablet.value ? "btn-sm" : "btn-sm");
        } else if (props.size === "md") {
            classes.push(isMobileOrTablet.value ?    ""    : "btn-sm");
        } else if (props.size === "lg") {
            classes.push(isMobileOrTablet.value ? "btn-lg" :    ""   );
        }
        if (props.variant === "contained") {
            classes.push(`btn-${props.color}`);
        } else if (props.variant === "outline") {
            classes.push(`btn-outline-${props.color}`);
        } else if (props.variant === "link") {
            classes.push("btn-link", "border-0", `text-${props.color}`);
        }
        if (iconSide.value === "right") {
            classes.push("flex-row-reverse");
        } else if (iconSide.value === "top") {
            classes.push("flex-column");
        } else if (iconSide.value === "bottom") {
            classes.push("flex-column-reverse");
        } else {
            // "left" and anything else is flex-row
            classes.push("flex-row");
        }
        if (props.grow) {
            classes.push("flex-grow-1");
        }
        if (props.disabled) {
            classes.push("disabled");
        }
        return classes.join(" ");
    });

    // style
    const styleSheet = computed(() => {
        const style = {};
        if (props.variant === "link") {
            if (hasIcon.value && !props.text) {
                // icon button
                style.width = "1.5em";
                style.height = "1.5em";
                if (isMobileOrTablet.value) {
                    style.margin = "-0.75em";
                    style.padding = "1.5em";
                } else {
                    style.margin = "-0.25em";
                    style.padding = "1.0em";
                }
            } else {
                // text button
                style.height = "1.5em";
                style.margin = "-0.5em -0.25em";
                style.padding = "0.5em 0.5em";
            }
        }
        if (!isClickable.value) {
            style.cursor = "unset";
            style.textDecoration = "none";
        }
        return style;
    });

    onMounted(() => {
        if (props.target && elRef.value) {
            props.target(elRef.value);
        }
    });
</script>

<template>
    <a
        ref="elRef"
        class="d-flex justify-content-center align-items-center gap-1"
        :class="className"
        :style="styleSheet"
        :href="href"
        :disabled="disabled"
        :target="(newTab && !isMobileOrTablet) ? '_blank' : undefined"
        v-bind="$attrs"
        @click="emit('click', $event)"
    >
        <!-- icon / spinner -->
        <template v-if="loading">
            <div>
                <div class="spinner-border spinner-border-sm" />
            </div>
        </template>
        <template v-else-if="icon">
            <i class="bi" :class="icon" />
        </template>

        <!-- text -->
        <template v-if="text">
            <div>
                {{ text }}
            </div>
        </template>
    </a>
</template>
